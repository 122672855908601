import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    redirect: "/login",
  },
  {
    path: "/dashboard/piket",
    name: "dashboardpiket",
    component: () => import("../views/DashboardPiket.vue"),
  },
  {
    path: "/dashboard/guru",
    name: "dashboardguru",
    component: () => import("../views/DashboardGuru.vue"),
  },
  {
    path: "/absensisiswa/:kelas?",
    name: "absensi",
    component: () => import("../views/absensiSiswa.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginForm.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (token) {
        next("/cek/role");
      } else {
        next();
      }
    },
  },
  {
    path: "/forgot",
    name: "forgot",
    component: () => import("../views/forgotPassword.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("token");
      if (token) {
        next("/cek/role");
      } else {
        next();
      }
    },
  },
  {
    path: "/face-detector",
    name: "FaceDetector",
    component: () => import("../views/FaceDetector.vue"),
  },
  {
    path: "/cek/role",
    name: "cekRole",
    component: () => import("../views/CekRole.vue"),
  },
  {
    path: "/train/face/siswa",
    name: "training face",
    component: () => import("../views/formTrainingFace.vue"),
  },
  {
    path: "/train/face/vip",
    name: "training ssface",
    component: () => import("../views/formTrainingFaceVIP.vue"),
  },
  {
    path: "/face-detector-vip",
    name: "facedetektorvip",
    component: () => import("../views/FaceDetectorVIP.vue"),
  },
  {
    path: "/logout",
    name: "Logot",
    component: () => import("../views/LogOutForm.vue"),
  },
  {
    path: "/change-password",
    name: "changePassword",
    component: () => import("../views/changePassword.vue"),
  },
  {
    path: "/secrt",
    name: "adminPeg",
    component: () => import("../views/AdminPage.vue"),
  },
  {
    path: "/secrtt/:event?",
    name: "AdminPageUpdateDatabase",
    component: () => import("../views/UpdateDatabase.vue"),
  },
  {
    path: "/secrttt/:event?",
    name: "JadwalGuruUpdate",
    component: () => import("../views/JadwalGuruViews.vue"),
  },
  {
    path: "/secret/:action?",
    name: "TambahData",
    component: () => import("../views/tambahData.vue"),
  },
  {
    path: "/edit-profile",
    name: "editProfile",
    component: () => import("../views/editProfile.vue"),
  },
  {
    path: "/secrtt/databaseLogs",
    name: "DatabaseLogs",
    component: () => import("../views/DatabaseLogs.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notfound",
    redirect: "/notfound",
  },
  {
    path: "/notfound",
    name: "notfounds",
    component: () => import("../views/notFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const isValidPath = (path) => {
  const invalidPaths = ["../", "%2e%2e%2f", "%2e%2e/", "/bin"];
  return !invalidPaths.some((invalidPath) => path.includes(invalidPath));
};

router.beforeEach((to, from, next) => {
  if (isValidPath(to.path)) {
    next();
  } else {
    next(false);
  }
});

export default router;
